/**
 * jQuery
 */
window.$ = window.jQuery = require('jquery');

/**
 * Bootstrap
 */
bootstrap = require('bootstrap');

/**
 * Fancybox
 */
require('@fancyapps/fancybox');

/**
 * Waypoints
 */
require('waypoints/lib/jquery.waypoints');
require('waypoints/lib/shortcuts/infinite');
require('waypoints/lib/shortcuts/inview');
require('waypoints/lib/shortcuts/sticky');


/**
 * Template
 */
require('./navigation');
// require('./search');
// require('./waypoints');
require('./fancybox');
require('./swiper');
require('./parallax');
require('./animateCounter');
require('./grid');
require('./scrollTo');
require('./template');
require('./newsSlider');
