/**
 * Fancybox
 */
$(function () {
    var classicFancyBox = $('.fancybox, a[rel="fancybox"]');

    classicFancyBox.map((index, element) => {
        var el = $(element),
            rel = el.attr('rel') || null;

        if (rel) el.attr('data-fancybox', rel);
    });

    classicFancyBox.fancybox({
        backFocus: false,
        buttons: [
            'slideShow',
            'thumbs',
            'download',
            'close'
        ],
        thumbs: {
            autoStart: false
        },
        caption: function (instance, item) {
            return $(this).find('figcaption').html();
        }
    });
});
