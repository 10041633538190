/**
 * Swiper bundle
 */

import Swiper from 'swiper/bundle';

$(function (){
    const observer = new IntersectionObserver((elemnts) => {
        elemnts.forEach((element) => {
            if(element.isIntersecting) {
                observer.disconnect();
                element.target.swiper.autoplay.start();
            }
        })
    })
    var swiperSliders = document.querySelectorAll('.newsSlider');
    swiperSliders.forEach(slider => {
        observer.observe(slider);
    });
    const swiper = new Swiper(".newsSlider", {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            type: "progressbar"
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        lazy: {
            loadPrevNext: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });
})
