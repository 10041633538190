/**
 * Navigation
 */
$(function () {
    if ("ontouchstart" in document.documentElement) {
        $('.navbar-nav').addClass('touch-nav');
    } else {
        $('.navbar-nav').removeClass('touch-nav');
    }
});

$.fn.navigationBar = function () {
    var parent = $(this);

    parent.each((index, el) => {
        var self = $(el);

        self.on('hide.bs.collapse', function () {
            $('.navbar').removeClass('show');

        });

        self.on('show.bs.collapse', function () {
            parent.trigger('collapse.navigation.bar', index);
            $('.navbar').addClass('show');

            var _input = self.find('input[name="tx_indexedsearch_pi2[search][sword]"]');

            if (_input.length) {
                setTimeout(function () {
                    _input.focus();
                }, 400)
            }
        });

        self.on('collapse.navigation.bar', function (e, targetIndex) {
            if (targetIndex !== index) {
                self.collapse('hide');
            }
        });
    });
}

$.fn.dropDownMenu = function () {
    this.map(function (index, el) {
        var self = $(el),
            link = self.find(' > .nav-link, > .dropdown-link'),
            dropdown = self.find(' > .dropdown-menu');
        // Create Toggle Button
        link.append('<span class="trigger"/>');
        // Add Label to SubNav
        dropdown.prepend('<li class="list-header">' + link.text() + '</li>');
        // Add CLick Bindings
        var btn = link.find('.trigger'),
            header = dropdown.find('> .list-header');
        // Bind Open Handler
        btn.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            dropdown.addClass('show');
        });
        // Bind Close Handler
        header.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            dropdown.removeClass('show');
        });
    });
}


/**
 * Nav on touch device
 */
$.fn.dropDownMenuTouchDevice = function () {
    this.map(function (index, el) {
        var self = $(el),
            navItemDropdown = self.find('.dropdown > a');
        navItemDropdown.on('click', (e) => {
            if (window.matchMedia('(min-width: 992px)').matches) {
                e.preventDefault();
            }
        });
    });
}


/**
 * Init Navigation
 */
$(function () {
    $('#collapseSearch').navigationBar();
    $('.navbar-main-navigation .dropdown').dropDownMenu();
    $('.touch-nav').dropDownMenuTouchDevice();

    /**
     * Position dropdown left/right
     */
    if (window.screen.width >= 1024 && window.screen.height >= 768) {
        $('.nav-item.dropdown, .dropdown-item.dropdown').on('mouseenter', function () {
            var dropdownList = $(this).find('.dropdown-menu'),
                dropdownWidth = dropdownList.width(),
                dropdownOffset = dropdownList.offset(),
                offsetLeft = dropdownOffset.left,
                docWidth = $(window).width(),
                isDropdownVisible = (offsetLeft + dropdownWidth <= docWidth);

            if (!isDropdownVisible) {
                dropdownList.addClass('dropdown-menu-left');
            }
        }).on('mouseleave', function () {
            var dropdownList = $(this).find('.dropdown-menu');

            dropdownList.removeClass('dropdown-menu-left');
        });
    }
});

$(function () {
    $('.navbar-toggler').on('click', function () {
        $('body').toggleClass('open-nav');
    })

    $('.page-header .navbar-nav .nav-item.dropdown .nav-link .trigger').on('click', function () {
        $('.page-header .navbar-nav').addClass("overflow-hidden")
        $('.page-header .dropdown-menu.show').parents('.dropdown-menu').addClass('overflow-hidden')
    })
    $('.page-header .navbar-nav .nav-item.dropdown>.dropdown-menu>.list-header').on('click', function () {
        $('.page-header .navbar-nav').removeClass("overflow-hidden")
        $('.page-header .dropdown-menu.show').parents('.dropdown-menu').removeClass('overflow-hidden')
    })


    $('.page-header .navbar-nav .dropdown .dropdown-link .trigger').on('click', function () {
        $('.page-header .dropdown-menu.show').parents('.dropdown-menu').addClass('overflow-hidden')
    })
    $('.page-header .navbar-nav .dropdown .dropdown-menu .list-header').on('click', function () {
        $(this).parents('.dropdown-menu.show').first().removeClass('overflow-hidden');
    })
})
