/**
 * Grid
 */
$.fn.gridOutside = function (columns, position = 'col-left-outside') {
    var column,
        colType = columns.find('[class^=\'outside-type-\']'),
        minHeight = 0;

    if (position === 'col-left-outside') {
        column = columns.eq(1);
    } else {
        column = columns.eq(0);
    }

    // Media
    if (colType.attr('class') === 'outside-type-media') {
        if (column.attr('class').indexOf('min-height') !== -1) {
            var classList = column.attr('class').split(/\s+/);
            $.each(classList, function (index, item) {
                if (item.indexOf('min-height') !== -1) {
                    minHeight = item.split('min-height-')[1] + 'px';
                }
            });
        } else {
            minHeight = column.height();
        }

        if (column.height() > 450) {
            colType.css({'max-height': minHeight, 'min-height': ''});
        } else {
            colType.css({'max-height': 450, 'min-height': ''});
        }

        if (window.matchMedia("(min-width: 992px)").matches) {
            if (column.height() > 600) {
                colType.css({'height': column.height(), 'max-height': ''});
            } else {
                colType.css({'height': column.height(), 'max-height': 600});
            }
        }
    }

    // Text
    if (colType.attr('class') === 'outside-type-text') {
        colType.css({'max-width': column.width()});
    }
};


$(window).on("load resize", function () {
    $('.grid-col-outside').each(function () {
        var columns = $(this).find('[class^=\'col-\']'),
            position = $(this).attr('class').split(/\s+/)[1];

        $(this).gridOutside(columns, position);
    })
});
