/**
 * Animate Counter
 */
$.fn.animatedCount = function (duration = 4000, format) {
    $(this).each(function () {
        $(this).on('animationTrigger', function () {
            $(this).prop('Counter', 0).animate({
                Counter: $(this).attr('counter-num')
            }, {
                duration: duration,
                easing: 'swing',
                step: function (now) {
                    $(this).text((format ? Math.ceil(now).toLocaleString(format) : Math.ceil(now)));
                }
            });
        })
    })
};

/**
 * Init Animate Counter
 */
$(function () {
    $('.oe-anim-counter .count .counter_num').each(function() {
        if($(this).attr('counter-format') == 2){
            $(this).animatedCount(4000,'de');
        }else if($(this).attr('counter-format') == 3){
            $(this).animatedCount(4000,'en');
        }else{
            $(this).animatedCount();
        }
    });

    $('.oe-anim-counter .count .counter_num').waypoint(function (direction) {
        if (direction == 'down') {
            this.adapter.$element.trigger('animationTrigger');
            this.destroy();
        }
    }, {
        offset: '90%'
    });
});