/**
 * Parallax
 */
$(function () {
    var parallaxBanner = $('.ce-wrapper.bg-parallax');

    parallaxBanner.each(function () {
        var banner = $(this),
            image = $(this).find('.inner-parallax');

        image.css('top', ($(window).scrollTop() + $(window).height() - banner.offset().top) / 20 * -1 + '%');
        $(window).scroll(function () {
            image.css('top', ($(window).scrollTop() + $(window).height() - banner.offset().top) / 20 * -1 + '%');
        });
    })
});
