/**
 * Scroll to
 */
function scrollToAnchor(_selector, offset) {
    if (_selector.indexOf("#") >= 0) {
        var anchor = $('#' + _selector.split("#")[1]),
            anchorTop = anchor.offset().top;

        if (offset) {
            anchorTop = anchorTop - parseInt($('#page-header').outerHeight());
        }

        if (anchor && anchor.length > 0) {
            $('html, body').stop().animate({
                scrollTop: anchorTop
            }, 500);

            return true;
        }
    }

    return false;
}

/**
 * Scroll on page load
 */
$(function () {
    // var uri = window.location.toString();
    // if (uri.indexOf("#") > 0) {
    //     var clean_uri = uri.substring(0, uri.indexOf("#"));
    //     window.history.replaceState({}, document.title, clean_uri);
    // }

    if (window.location.hash) {
        setTimeout(function () {
            scrollToAnchor(window.location.hash);
        }, 150);
    }
});

/**
 * Scroll to Anchor
 */
$(document).on('click', 'a[href^="#"]', function (e) {
    e.preventDefault();
    if ($(this).attr('no-scroll') != 1) {
        scrollToAnchor($(this).attr('href'));
    }
});

$(document).on('click', 'a[href^="/#"]', function (e) {
    if ($(this).attr('no-scroll') != 1) {
        scrollToAnchor($(this).attr('href'));
    }
});

/**
 * Scroll to Top button
 */
$('.scroll-top').on('click', function (e) {
    e.preventDefault();

    $('html, body').stop().animate({
        scrollTop: 0
    });
});

$(window).on('scroll', function () {
    if ($(this).scrollTop() > 300) {
        $('.scroll-top').addClass('scroll-top-visible');
    } else {
        $('.scroll-top').removeClass('scroll-top-visible');
    }

    if ($(this).scrollTop() > 100) {
        $('.mouse-pointer').addClass('scrolled');
    } else {
        $('.mouse-pointer').removeClass('scrolled');
    }
});

$(document).ready(function () {
    var scrollDistance = $('.oe-slider img').outerHeight();

    $("#scroll-down").on("click", function () {
        $("html, body").animate({scrollTop: "+=" + scrollDistance + "px"}, "slow");
    });
});

