/**
 * Template
 */
function resize() {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    var mainNavigationHeight = $('#page-header').outerHeight();
    $('.page-content section:first-child').css("margin-top", mainNavigationHeight);

    $('.backendlayout-onepager_default .page-header .nav-link').click(function () {
        if (window.matchMedia('(max-width: 992px)').matches) {
            $('.navbar-toggler').click();
        }
    })

    $('.backendlayout-onepager_subpage .page-header .nav-link').click(function () {
        if (window.matchMedia('(max-width: 992px)').matches) {
            $('.navbar-toggler').click();
        }
    })
}

function mainContentMargin() {
    var nav = $('.page-header'),
        topSlider = $('.header-content .oe-slider'),
        topSliderHeight = 0;

    if (topSlider.length) {
        topSlider.css('margin-top', nav.height());
        topSlider = $('.header-content .oe-slider .swiper');
        topSliderHeight = topSlider.height();
    }

    var margin = nav.height() + topSliderHeight;
    $('.main-content').css('margin-top', margin);
}

function powermailPreselect() {
    if ($('.powermail_form_2').length >= 1) {
        var normalizedUrlParam = normalizeString(decodeURIComponent(getURLParameter()));
        $("#powermail_field_bewerbung option").each(function () {
            var optionValue = $(this).val();
            var normalizedOptionValue = normalizeString(optionValue);

            if (normalizedOptionValue === normalizedUrlParam) {
                $(this).prop("selected", true);
                return false;
            }
        });
    }
}

function breadcrumbSpacing() {
    const breadcrumHeight = $('ol.breadcrumb').outerHeight(true);

    const elementBehindBreadcrumb = $('ol.breadcrumb').parents('.container').next();
    elementBehindBreadcrumb.css(
        {
            "padding-top": `${breadcrumHeight - 60}px`
        }
    )
}

function normalizeString(str) {
    return str.toLowerCase()
        .replace(/[^\w\s]/gi, '')
        .replace(/_/g, ' ')
        .replace(/\s+/g, ' ')
        .trim();
}

function getURLParameter() {
    var sPageURL = window.location.search.substring(1);
    return sPageURL.split('=')[0];
}

function pageContentMargin() {
    if ($('.header-content').length > 0) {
        $('.main-content').addClass('headertrue');
    }
}

function openAccordion() {
    var url = window.location.href;
    var scrollAnchor = url.substring(url.indexOf("#accordion-item-") + 1);

    if (scrollAnchor.indexOf("accordion-item") > -1) {
        var accordion = $('#' + scrollAnchor + ' .accordion-collapse');
        accordion.collapse();
    }
}

function morelink() {
    $('.showmore .d-none-more').css('display', 'none');
    $(".morebutton a").click(function () {
        $('.showmore .d-none-more').css('display', 'block');
        $('.morebutton').css('display', 'none');

    });
}

function crossMedia() {
    var popout = $('.popout .pop_out .item');
    var lastElement = null;
    popout.click(function (event) {
        if ($(this).find('.icon-headline').length >= 1) {
            if (lastElement === null || lastElement.get(0) !== this) {
                lastElement = $(this);
                event.preventDefault();
            }
        }
    });
}

// Use
window.addEventListener("load", function () {
    resize();
    powermailPreselect();
    pageContentMargin();
    morelink();
    openAccordion();
    breadcrumbSpacing();
});

window.addEventListener("resize", function () {
    resize();
    pageContentMargin();
    breadcrumbSpacing();
});

$(function () {
    if ("ontouchstart" in document.documentElement) {
        crossMedia();
    }
});
