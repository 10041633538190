/**
 * Swiper bundle
 */
import Swiper from 'swiper/bundle';

// import styles bundle
// import 'swiper/css/bundle';

const observer = new IntersectionObserver((elemnts) => {


    elemnts.forEach((element) => {

        if(element.isIntersecting) {


            observer.unobserve(element.target);
            element.target.swiper.autoplay.start();
        }
    })
})
/**
 * Init Swiper
 */
$(function () {
    var swiperSliders = document.querySelectorAll('.default-swiper');

    swiperSliders.forEach(slider => {
        let swiperOptions = {},
            swiperSliderClasses = slider.className,
            swiperSliderMode = swiperSliderClasses.match('swiper-fade'),
            swiperSliderIndicatorMode = swiperSliderClasses.match(/swiper-indicator-(\w+)/),
            swiperSliderFullscreen = swiperSliderClasses.match('swiper-fullscreen'),
            swiperSliderItems = swiperSliderClasses.match(/swiper-items-(\d)-(\d)-(\d)/),
            swiperSliderGutter = swiperSliderClasses.match(/swiper-gutter-(\d+)/),
            swiperSliderSlideBy = swiperSliderClasses.match('swiper-slide-page'),
            swiperSliderItemCenter = swiperSliderClasses.match('swiper-item-center'),
            swiperSliderLoop = swiperSliderClasses.match('swiper-loop'),
            swiperSliderNav = swiperSliderClasses.match('swiper-nav'),
            swiperSliderDots = swiperSliderClasses.match('swiper-dots'),
            swiperSliderSpeed = swiperSliderClasses.match(/swiper-speed-(\d+)/),
            swiperSliderAutoplaySpeed = swiperSliderClasses.match(/swiper-autoplay-speed-(\d+)/),
            swiperSliderAutoplayPause = swiperSliderClasses.match('swiper-autoplay-pause'),
            swiperSliderTouchMove = swiperSliderClasses.match('swiper-disable-touch-move'),
            swiperSliderAutoWidth = swiperSliderClasses.match('swiper-auto-width'),
            swiperSliderUpDown = swiperSliderClasses.match('swiper-up-down'),
            swiperLoadPrevNext = swiperSliderClasses.match('swiper-lazy-load-prev-next'),
            swiperAutoplayObserver = swiperSliderClasses.match('swiper-autoplay-observer');

        // Default parameters
        swiperOptions = {
            loop: (swiperSliderLoop ? true : false),
            allowTouchMove: (swiperSliderTouchMove ? false : true),
            slidesPerView: (swiperSliderAutoWidth ? 'auto' : parseInt(swiperSliderItems[1])),
            centeredSlides: (swiperSliderItemCenter ? true : false),
            spaceBetween: (swiperSliderGutter ? parseInt(swiperSliderGutter[1]) : 0),
            speed: (swiperSliderSpeed ? parseInt(swiperSliderSpeed[1]) : 300),
            lazy: true,
        }

        if (swiperAutoplayObserver) {
            observer.observe(slider)
        }

        if (swiperLoadPrevNext) {
            swiperOptions.lazy = {
                loadPrevNext: true
            }
        }

        // Responsive breakpoints
        if (!swiperSliderAutoWidth) {
            swiperOptions.breakpoints = {
                768: {
                    slidesPerView: parseInt(swiperSliderItems[2]),
                },
                1200: {
                    slidesPerView: parseInt(swiperSliderItems[3]),
                }
            }
        }

        // Mode
        if (swiperSliderMode) {
            swiperOptions.effect = 'fade'
            swiperOptions.fadeEffect = {
                crossFade: true
            }
        }

        // Autoplay
        if (swiperSliderAutoplaySpeed) {
            swiperOptions.autoplay = {
                delay: (swiperSliderAutoplaySpeed ? parseInt(swiperSliderAutoplaySpeed[1]) : 3000),
                disableOnInteraction: false,
            }
            if (swiperSliderAutoplayPause) {
                swiperOptions.autoplay.pauseOnMouseEnter = true
            }
        }

        // Up and down
        if (swiperSliderUpDown) {
            swiperOptions.loopedSlides = 3;
        }

        // Slides per group
        if (swiperSliderSlideBy) {
            swiperOptions.slidesPerGroup = parseInt(swiperSliderItems[1])
            swiperOptions.breakpoints = {
                768: {
                    slidesPerView: parseInt(swiperSliderItems[2]),
                    slidesPerGroup: parseInt(swiperSliderItems[2]),
                },
                1200: {
                    slidesPerView: parseInt(swiperSliderItems[3]),
                    slidesPerGroup: parseInt(swiperSliderItems[3]),
                }
            }
        }

        // Pagination
        if (swiperSliderDots) {
            swiperOptions.pagination = {
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets',
            }

            if (swiperSliderIndicatorMode[1] === 'dpoints') {
                swiperOptions.pagination.dynamicBullets = true
            }
            if (swiperSliderIndicatorMode[1] === 'strips') {
                swiperOptions.pagination.bulletClass = 'swiper-pagination-strip'
                swiperOptions.pagination.bulletActiveClass = 'swiper-pagination-strip-active'
            }
            if (swiperSliderIndicatorMode[1] === 'progress') {
                swiperOptions.pagination.type = 'progressbar'
            }
        }

        // Navigation arrows
        if (swiperSliderNav) {
            swiperOptions.navigation = {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        }

        const swiper = new Swiper(slider, swiperOptions);
    });
});
